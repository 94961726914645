<template>
  <ul id="element-settings" class="uk-switcher uk-margin">
    <li class="uk-active">
      <div class="uk-width-1-1">
        <div class="uk-background-default uk-padding-small">
          <div class="uk-form-stacked">
            <div class="uk-grid uk-child-width-1-2@m uk-flex uk-flex-middle" data-uk-grid>
              <div>
                <label class="uk-form-label">Schnittstelle</label>
                <select class="uk-select" v-model="form.api">
                  <option value="webkfz">Webkfz</option>
                  <option value="aos">AOS</option>
                  <option value="dab">DAB</option>
                  <option value="eln">ELN</option>
                </select>
              </div>
              <div>
                <div class="uk-margin-top">
                  <label class="uk-form-label">&nbsp;</label>
                  <label>
                    <input type="checkbox" class="uk-checkbox" v-model="form.random"> Zufällige Fahrzeuge
                  </label>
                </div>
              </div>
            </div>
            <div class="uk-margin">
              <div class="uk-grid uk-child-width-1-3@m" uk-grid>
                <div v-if="!form.random">
                  <label class="uk-form-label">Hersteller</label>
                  <select class="uk-select" v-model="form.make">
                    <option value="" disabled>Bitte wählen</option>
                    <option v-for="make in makeModels" :key="make.value" :value="make.value">{{ make.label }}</option>
                  </select>
                </div>
                <div v-if="!form.random">
                  <label class="uk-form-label">Modell</label>
                  <select class="uk-select" v-model="form.model">
                    <option value="" disabled>Bitte wählen</option>
                    <option v-for="model in availableModels" :key="model.value" :value="model.value">{{ model.label }}</option>
                  </select>
                </div>
                <div>
                  <label class="uk-form-label">Anzahl Fahrzeuge</label>
                  <div class="uk-grid-small uk-flex-middle uk-grid" uk-grid>
                    <div class="uk-width-expand">
                      <input class="uk-range" type="range" value="20" min="1" max="50" step="1" v-model="form.count">
                    </div>
                    <div class="uk-width-auto">
                      <input type="text" class="uk-input uk-form-width-xsmall" v-model="form.count">
                    </div>
                  </div>
                </div>
              </div>
              <div class="uk-grid uk-child-width-1-3@m" uk-grid>
                <div>
                  <label class="uk-form-label">Layout</label>
                  <select class="uk-select" v-model="form.layout">
                    <option value="list">Liste</option>
                    <option value="cards">Karten</option>
                  </select>
                </div>
                <div v-if="form.layout === 'cards'">
                  <label class="uk-form-label">Anzahl Spalten</label>
                  <select class="uk-select" v-model="form.card_columns">
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                  </select>
                </div>
                <div>
                  <label class="uk-form-label">Detail Link Typ</label>
                  <select class="uk-select" v-model="form.urlType">
                    <option value="new">Neu</option>
                    <option value="old">Old</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </li>
    <li>
      <div class="uk-width-1-1 uk-margin">
        <div>
          <p>Keine weiteren Einstellungen</p>
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
import 'vue-select/dist/vue-select.css'
import VehicleService from '@/services/vehicle.service'
export default {
  props: {
    data: {
      required: true,
      type: Object
    }
  },
  components: {
  },
  data () {
    return {
      makeModels: [],
      form: {
        api: 'webkfz'
      }
    }
  },
  mounted () {
    this.getMakeModels(this.form.api)
    this.form = this.data
  },
  watch: {
    'form.make': function (val, oldVal) {
      if (typeof oldVal !== 'undefined' && oldVal !== null && val !== oldVal) {
        this.form.model = ''
      }
    },
    'form.api' (val, oldVal) {
      if (typeof oldVal !== 'undefined' && oldVal !== null && val !== oldVal) {
        this.form.make = ''
        this.form.model = ''
        // if(val == 'aos') {
        //  this.getMakeAosModels()
        // } else {
        this.getMakeModels(val)
        // }
      }
    }
  },
  computed: {
    availableModels () {
      // eslint-disable-next-line no-undef
      const selectedMakeObj = _.find(this.makeModels, m => {
        return m.value === this.form.make
      })
      return selectedMakeObj ? selectedMakeObj.models : []
    }
    /*
    selectedMake: {
      get() {
        const makes = _.keyBy(this.makeModels, 'key')
        return makes[this.form.make] || null
      },
      set(make) {
        this.form.make = make ? make.key : null
      }
    },
    selectedModel: {
      get() {
        const models = _.keyBy(this.availableModels, 'key')
        return (models[this.form.model]) || null
      },
      set(model) {
        this.form.model = model ? model.key : null
      }
    }
    */
  },
  methods: {
    getMakeAosModels () {
      VehicleService.getAosMakeModels().then(response => {
        this.makeModels = response.data
      })
    },
    getMakeModels (type) {
      VehicleService.getMakeModels(type).then(response => {
        this.makeModels = response.data
      })
    },
    store () {
      // console.log(this.form)
    }
  }
}
</script>
